/**
 *  CV / Pages / 404
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  The 404 page of the CV
 *
 */

/** Dependencies */
import * as React from "react";
import { Helmet } from "react-helmet";

/** Local Dependencies */
import { Layout } from "../components/Layout";
import { Section } from "../components/Section";
import { Typography } from "../components/Typography";

const NotFoundPage = () => (
  <Layout>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Ivan Drinchev - CV</title>
      <link rel="canonical" href="http://cv.drinchev.com" />
    </Helmet>
    <Section title="Page Not Found" head>
      <Typography>Page was not found</Typography>
    </Section>
  </Layout>
);

export default NotFoundPage;
